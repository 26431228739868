import React from 'react';
import StepForm from '../../common/components/StepForm';
import defaultYesNoOptions from '../../common/defaultYesNoOptions';
import { SubFormPanel } from '../../common/Panel';
import {
  DropDownControl,
  FancyCheckboxControl,
  InputControl,
} from '~common/molecules/form-controls';
import AvantGroupField from './AvantGroupField';
import { P3 } from '~common/atoms/typography';
import { Box } from 'rebass';
import Link from '~common/atoms/Link';
import CheckBoxControl from '~common/molecules/form-controls/CheckBoxControl';
import styled from '@emotion/styled';
import useAppFormNominatePersonRelationshipOptions from '../../state/options/useAppFormNominatePersonRelationshipOptions';
import useAppFormHowDidYouHearForUsOptions from '../../state/options/useAppFormHowDidYouHearForUsOptions';
import StepTitle from '../../common/components/StepTitle';
import useAppFormPartnerOrganisationsOptions from '../../state/options/useAppFormPartnerOrganisationsOptions';
import { css } from '@emotion/core';
import FeatureToggle from '~common/atoms/FeatureToggle';
import PromotionCodeField from './PromotionCodeField';
import PromotionsBanner from '~common/molecules/PromotionsBanner';
import { useLocale } from '~common/locales';

const StyledCheckBoxControl = styled(CheckBoxControl)`
  margin-bottom: 0px;
  &&&& .control-wrapper {
    align-items: flex-start;
  }
`;
const StyledCheckBoxControlWithMargin = styled(StyledCheckBoxControl)`
  margin-bottom: 22px;
`;

const AvantExclusiveOfferBanner = ({ content }) => {
  return <PromotionsBanner asRichText={true} content={content} />;
};

const AdditionalDetails = ({ appFormContext, context }) => {
  const relationshipOptions = useAppFormNominatePersonRelationshipOptions();
  const howDidYouHearForUsOptions = useAppFormHowDidYouHearForUsOptions();
  const partnerOrganisationsOptions = useAppFormPartnerOrganisationsOptions();
  const { getLocaleString } = useLocale();
  return (
    <StepForm title="Additional information" appFormContext={appFormContext}>
      {({ Field, handleChange, values }) => (
        <>
          <Field
            name="isNominatePerson"
            label={({ LabelContainer }) => (
              <Box>
                <LabelContainer
                  tooltip={{
                    translateX: {
                      tabletP: '-453px',
                    },
                    text: 'You do not need to add your partner’s details here if they are going to be covered under this policy.',
                  }}
                >
                  Do you wish to nominate a person other than yourself as an
                  authorised person on the policy?
                </LabelContainer>
              </Box>
            )}
            component={FancyCheckboxControl}
            onChange={handleChange}
            options={defaultYesNoOptions}
          />
          {values.isNominatePerson && (
            <SubFormPanel>
              <Field
                onChange={handleChange}
                name="nominatePersonFullName"
                label="Enter their full name"
                component={InputControl}
              />
              <Field
                name="nominatePersonRelationship"
                label="Relationship to you"
                onChange={handleChange}
                component={DropDownControl}
                placeholder="Select one"
                options={relationshipOptions}
                bottomSpacing={false}
              />
            </SubFormPanel>
          )}

          {context.showAvantQuestions && (
            <>
              <AvantGroupField
                onChange={handleChange}
                values={values}
                label="Are you currently a member of Avant?"
                fieldNames={{
                  question: 'isAvantGroupMember',
                  memberNumber: 'avantMemberNumber',
                  information: 'avantMemberInformation',
                }}
              />
              {appFormContext.state.avantExclusivePromotion?.isEligible &&
                values.isAvantGroupMember && (
                  <AvantExclusiveOfferBanner
                    content={
                      appFormContext.state.avantExclusivePromotion?.message
                    }
                  />
                )}
            </>
          )}
          {values.hasPartner && context.showAvantQuestions && (
            <>
              <AvantGroupField
                onChange={handleChange}
                values={values}
                label="Is your partner currently a member of Avant?"
                fieldNames={{
                  question: 'isPartnerAvantGroupMember',
                  memberNumber: 'partnerAvantMemberNumber',
                  information: 'partnerAvantMemberInformation',
                }}
              />
              {appFormContext.state.avantExclusivePromotion?.isEligible &&
                values.isPartnerAvantGroupMember &&
                !values.isAvantGroupMember && (
                  <AvantExclusiveOfferBanner
                    content={
                      appFormContext.state.avantExclusivePromotion?.message
                    }
                  />
                )}
            </>
          )}

          {!!partnerOrganisationsOptions?.length && (
            <Field
              onChange={handleChange}
              component={DropDownControl}
              halfWidth
              placeholder="Select"
              name="partnerOrganisationMember"
              label={
                values.hasPartner
                  ? 'Are either of you a member of one of our partner organisations? (optional)'
                  : 'Are you a member of one of our partner organisations? (optional)'
              }
              options={partnerOrganisationsOptions}
            />
          )}
          {!!howDidYouHearForUsOptions?.length && (
            <Field
              name="howDidYouHearAboutUs"
              label="How did you hear about us?"
              onChange={handleChange}
              component={DropDownControl}
              placeholder="Select"
              options={howDidYouHearForUsOptions}
            />
          )}
          <FeatureToggle
            name="referrals"
            render={() => (
              <Field
                name="memberReferralCode"
                placeholder="MGMXXXXXX"
                component={InputControl}
                label={({ LabelContainer }) => (
                  <Box>
                    <LabelContainer
                      tooltip={{
                        text: (
                          <>
                            Have you been referred by someone who is already a
                            member of{' '}
                            {getLocaleString('common.organisationName')}? With
                            our Refer-a-Friend promotion, both you and your
                            referrer will receive a $100 Flexi eGift card once
                            you join. Click{' '}
                            <Link
                              css={css`
                                color: white;
                              `}
                              external
                              target="_blank"
                              to="/refer-a-friend"
                            >
                              here
                            </Link>{' '}
                            to find out more, terms and conditions apply.{' '}
                          </>
                        ),
                      }}
                    >
                      Have you been referred by a friend or colleague?
                    </LabelContainer>
                    <P3 my="3px">Enter your member referral code</P3>
                  </Box>
                )}
                onChange={handleChange}
              />
            )}
          />

          <PromotionCodeField
            formValues={values}
            promoCodeValidation={appFormContext.state.exclusivePromotions?.data}
            Field={Field}
            handleChange={handleChange}
          />

          <Field
            name="getInTouch"
            onChange={handleChange}
            component={FancyCheckboxControl}
            options={defaultYesNoOptions}
            label={({ LabelContainer }) => (
              <Box>
                <LabelContainer
                  tooltip={{
                    text: (
                      <>
                        We’d like to call you to welcome you to the fund, ensure
                        you have received all your policy information and as an
                        opportunity for you to ask any questions about your
                        cover.
                      </>
                    ),
                  }}
                >
                  Would you like us to check in with you after you join? This
                  will occur 4-6 weeks after completing your application.
                </LabelContainer>
              </Box>
            )}
          />

          <StepTitle mt={35}>Agree to terms and conditions</StepTitle>

          <Field
            name="acceptTermsAndConditions"
            onChange={handleChange}
            label={({ LabelContainer }) => (
              <LabelContainer>
                I have read and agree to the{' '}
                <Link to="/terms-of-use" external>
                  Terms and Conditions of Service.
                </Link>
              </LabelContainer>
            )}
            component={StyledCheckBoxControl}
            labelPosition="right"
          />

          <Field
            name="agreeUsingPersonalInformation"
            onChange={handleChange}
            label={({ LabelContainer }) => (
              <LabelContainer>
                I agree to The {getLocaleString('common.organisationName')}{' '}
                using my personal information in accordance with its{' '}
                <Link to="/privacy-policy" external>
                  Privacy Policy
                </Link>{' '}
                providing information and marketing material by phone, text
                message, mail or email.
              </LabelContainer>
            )}
            component={StyledCheckBoxControl}
            labelPosition="right"
          />
          <Field
            name="acceptAHSA"
            onChange={handleChange}
            label={({ LabelContainer }) => (
              <LabelContainer>
                I have read and agree to the{' '}
                <Link
                  to="https://www.ahsa.com.au/web/ahsa/privacy_policy"
                  external
                >
                  AHSA Privacy Policy.
                </Link>
              </LabelContainer>
            )}
            component={StyledCheckBoxControl}
            labelPosition="right"
          />
          <Field
            onChange={handleChange}
            name="confirmApplicationIsTrue"
            label={({ LabelContainer }) => (
              <LabelContainer>
                The information entered in this application is true and correct
                and I confirm I am eligible to join{' '}
                {getLocaleString('common.organisationName')}.
              </LabelContainer>
            )}
            component={StyledCheckBoxControlWithMargin}
            labelPosition="right"
          />
        </>
      )}
    </StepForm>
  );
};

export default AdditionalDetails;
