import React from 'react';
import Link from '~common/atoms/Link';
import CheckBoxControl from '~common/molecules/form-controls/CheckBoxControl';
import styled from '@emotion/styled';
import StepForm from '../../../../../ApplicationForm/common/components/StepForm';
import FormButtons from '../../../../../ApplicationForm/common/components/FormButtons';
import useFiles from '~lib/hooks/sanity/useFiles';
import { useLocale } from '~common/locales';

const StyledCheckBoxControl = styled(CheckBoxControl)`
  margin-bottom: ${props => props.mb || 0};
  &&& .control-wrapper {
    align-items: ${props => props.alignItems};
  }
`;

const AdditionalDetails = ({ appFormContext }) => {
  const termsAndConditionsPdf = useFiles('hospitalFreeExtrasTermsConditions');
  const { getLocaleString } = useLocale();
  return (
    <StepForm
      continueButton={
        <FormButtons
          showSaveButton={false}
          submitting={appFormContext.state.submitting}
          showArrow={false}
        >
          Upgrade
        </FormButtons>
      }
      onSubmit={evt => {
        if (appFormContext.state.isValid) {
          appFormContext.actions.submitApplicationForm(evt);
        }
      }}
      title="Terms & conditions"
      appFormContext={appFormContext}
    >
      {({ Field, handleChange, values }) => (
        <>
          <Field
            name="acceptTermsAndConditions"
            onChange={handleChange}
            label={({ LabelContainer }) => (
              <LabelContainer>
                I have read and agree to the{' '}
                <Link to="/terms-of-use" external>
                  Terms and Conditions of Service.
                </Link>
              </LabelContainer>
            )}
            component={StyledCheckBoxControl}
            labelPosition="right"
          />

          <Field
            name="agreeUsingPersonalInformation"
            onChange={handleChange}
            label={({ LabelContainer }) => (
              <LabelContainer>
                I agree to The {getLocaleString('common.organisationName')}{' '}
                using my personal information in accordance with its{' '}
                <Link external to="/privacy-policy">
                  Privacy Policy
                </Link>{' '}
                providing information and marketing material by phone, text
                message, mail or email.
              </LabelContainer>
            )}
            component={renderProps => (
              <StyledCheckBoxControl alignItems="flex-start" {...renderProps} />
            )}
            labelPosition="right"
          />
          <Field
            name="acceptAHSA"
            onChange={handleChange}
            label={({ LabelContainer }) => (
              <LabelContainer>
                I have read and agree to the{' '}
                <Link
                  to="https://www.ahsa.com.au/web/ahsa/privacy_policy"
                  external
                >
                  AHSA Privacy Policy.
                </Link>
              </LabelContainer>
            )}
            component={StyledCheckBoxControl}
            labelPosition="right"
          />
          <Field
            onChange={handleChange}
            name="confirmApplicationIsTrue"
            label={({ LabelContainer }) => (
              <LabelContainer>
                The information entered in this application is true and correct
                and I confirm I am eligible to join{' '}
                {getLocaleString('common.organisationName')}.
              </LabelContainer>
            )}
            component={renderProps => (
              <StyledCheckBoxControl
                mb="22px"
                alignItems="flex-start"
                {...renderProps}
              />
            )}
            labelPosition="right"
          />
          <Field
            onChange={handleChange}
            name="agreeFreeExtrasTerms"
            label={({ LabelContainer }) => (
              <LabelContainer>
                I have read and agree to the{' '}
                <Link to={termsAndConditionsPdf?.url} external newTab>
                  Terms & Conditions of Hospital + Subsidised Extras
                </Link>
              </LabelContainer>
            )}
            component={renderProps => (
              <StyledCheckBoxControl
                mb="22px"
                alignItems="flex-start"
                {...renderProps}
              />
            )}
            labelPosition="right"
          />
        </>
      )}
    </StepForm>
  );
};

export default AdditionalDetails;
