module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MJ69754","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://pre-prod.doctorshealthfund.com.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src3563414235/src/app/src/components/Root.js"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://40aca23a6c224ca0a046bc3286160271@o1546.ingest.sentry.io/5306445","environment":"pre-production","debug":false,"release":"52bee21130f2","tracesSampleRate":1,"ignoreErrors":["The result of this StaticQuery could not be fetched","undefined is not an object (evaluating 'a.takeRecords().map')","TypeError: undefined is not an object (evaluating 'ue.performance.getEntries().slice')","Network request failed","undefined is not an object (evaluating 'B(\"adBlocker\").toString')","Can't find variable: al_onAdViewRendered","Current activity not found. Make sure to call this method while your application is in foreground.","Failed to execute 'send' on 'XMLHttpRequest'"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Doctors' Health Fund","short_name":"DHF","theme_color":"#F36F21","icon":"src/images/icon.png","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"start_url":"/","background_color":"#F36F21","display":"standalone","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"186db61d2ab6bedc49cbfbd286e2e3fe"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
